<template>
    <section class="">
        <div class="grid lg:grid-cols-3 grid-cols-2 justify-between">
            <div class="flex justify-start items-center">
                <img src="@/assets/logo.png" class="w-16"/>
            </div>
            <div class="hidden lg:flex justify-center items-center ">
                <ul class="flex flex-row gap-10">
                    <li class="text-sm text-blue-primary text-bold"><a href="#home">Home</a></li>
                    <li class="text-sm text-blue-primary text-bold"><a href="#offering">Integrations</a></li>
                    <li class="text-sm text-blue-primary text-bold"><a href="#features">Features</a></li>
                </ul>
            </div>
            <div class="lg:hidden flex relative justify-end items-center">
                <ul>
                    <li @click="isActive = !isActive" class="bg-gray-200 p-2 pl-3 pr-3 text-blue-primary text-bold rounded-xl">Menu</li>
                </ul>
                <ul v-show="isActive" class="flex absolute flex-col left-0 w-max bg-white gap-2" style="width: 95vw;left: -22vh;padding: 20px;top: 50px;">
                    <li @click="() => isActive = !isActive" class="text-sm text-blue-primary text-bold"><a href="#home">Home</a></li>
                    <li @click="() => isActive = !isActive"  class="text-sm text-blue-primary text-bold"><a href="#offering">Integrations</a></li>
                    <li @click="() => isActive = !isActive" class="text-sm text-blue-primary text-bold"><a href="#features">Features</a></li>
                    <li>
                       <a href="https://alpha.leafbmp.com/register" target="_blank"><button class="bg-blue-primary text-sm text-white p-3 rounded-md pl-5 pr-5 text-bold hover:opacity-60">Sign up for free trial</button></a>
                    </li>
                </ul>
            </div>
            <div class="hidden lg:flex justify-end lg:w-auto w-max">
                <ul class="flex flex-row items-center gap-10">
                    <li class="text-sm text-blue-primary text-bold"><a href="https://alpha.leafbmp.com/login" target="_blank">Sign in</a></li>
                    <li>
                       <a href="https://alpha.leafbmp.com/register" target="_blank"><button class="bg-blue-primary text-sm text-white p-3 rounded-md pl-5 pr-5 text-bold hover:opacity-60">Sign up for free trial</button></a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    }
}
</script>