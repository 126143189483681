
export const skills = [
  {
    title: 'Nuxt',
    meta: 'framework',
  },
  {
    title: 'Ionic Vue',
    meta: 'mobile',
  },
  {
    title: 'Buttercms',
    meta: 'framework',
  },
  {
    title: 'vuetify',
    meta: 'template',
  },
  {
    title: 'vuex',
    meta: 'store',
  },
  {
    title: 'vue3',
    meta: 'version',
  },
  {
    title: 'Pinia',
    meta: 'store',
  },
  {
    title: 'Vue router',
    meta: 'store',
  },
  {
    title: 'tailwind',
    meta: 'styling',
  },
  {
    title: 'bootstrap',
    meta: 'styling',
  },
  {
    title: 'less',
    meta: 'preprocessor',
  },
]

export function truncateString(str, num) {
  str = str || ''
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
export function htmlToText(HTMLPart) {
  return (HTMLPart || '')
    .replace(/\n/ig, '')
    .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, '')
    .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, '')
    .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, '')
    .replace(/<\/\s*(?:p|div)>/ig, '\n')
    .replace(/<br[^>]*\/?>/ig, '\n')
    .replace(/<[^>]*>/ig, '')
    .replace('&nbsp;', ' ')
    .replace(/[^\S\r\n][^\S\r\n]+/ig, ' ')
}

export function optional(obj, key, defaultValue = null) {
  function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  // if falsy value was passed, return immediately
  if (!obj) return isFunction(defaultValue) ? defaultValue(obj, key) : defaultValue;

  let localValue = obj;
  let keys = key.split('.');

  for (let i of keys) {
    localValue = localValue[i];

    if (!localValue) break;
  }

  return localValue ? localValue : (isFunction(defaultValue) ? defaultValue(obj, key) : defaultValue);
}

export function toTitleCase(str) {
  return (str || '').replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function isValidUrl(string) {
  let url;

  try {
    url = new URL(string || '');
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function isValidEmail(email) {
  return (email || '')
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
function escapeReplacement(string) {
  return (string || '').replace(/[-[\]{}()*+!<=:?.\\/\\^$|#\s,]/g, '\\$&')
}

export const wordInString = (sentence, word) => {
  return new RegExp('\\b' + escapeReplacement(word) + '\\b', 'i').test(sentence || '')
}

export const random = (length = 8) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function rskills(description, title) {
  description = htmlToText(description || '').trim().toLowerCase();
  let rSkills = [];

  for (let skill of skills) {
    if (
      skill.title.length > 2 &&
      (wordInString(title || '', skill.title) ||
        (description.length > 0 && wordInString(description, skill.title)))
    ) {
      rSkills.push(skill.title);
    }
  }

  return rSkills;
}

export function waitForElement(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export function setTitle(params, title) {
  let keywordShuffle = ['Vuejs', 'Vue.js']
  let pageTitle = ''

  if (params.company) {
    pageTitle += `${keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]} Jobs At ${params.company} |`
  } else if (params.location) {
    pageTitle += `${keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]} Jobs In ${params.location} |`
  } else if (params.search) {
    pageTitle += `${params.search} Jobs |`
  } else if (params.type) {
    pageTitle += `${params.type} ${keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]} Jobs |`
  } else if (params.contract_type && params.contract_type.length) {
    let contractType = typeof params.contract_type === 'string' ? params.contract_type.split(',') : params.contract_type
    pageTitle += `${contractType.join(', ').replace(/, ([^,]*)$/, ' and $1')} ${keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]} Jobs |`
  } else {
    pageTitle = ``
  }
  let formalizedTitle = toTitleCase(pageTitle)
  document.title = `${formalizedTitle} ${title}`
  return formalizedTitle
}

export function slugify(text) {
  return (text || '')
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/_/g, '-')
}

export function unslugify(text) {
  return typeof text === 'string' ? text.replace(/-/g, ' ') : text
}

export function updateUrl(key, value) {
  let url = new URL(window.location);
  (url.searchParams.has(key) ? url.searchParams.set(key, value) : url.searchParams.append(key, value));

  url.search = url.searchParams;
  url = url.toString();

  // if you want to append into URL without reloading the page
  history.pushState({}, null, url);
}

export function generateMeta(metaData, newKeywords, newTitle, currentTime) {
      this.metaAdded = true;
       let {
        twitter,
        appUrl,
        keywords,
        title,
        description,
        jobTitles,
        siteName,
        logoUrl,
        description2,
      } = metaData
      let metaTags = `
     <meta name="description" content="${description}">
      <meta name="keywords" content="${keywords.concat(jobTitles).join(",")}">
<meta name="apple-mobile-web-app-capable" content="yes">
<meta name="apple-mobile-web-app-status-bar-style" content="black">
 <meta http-equiv="cleartype" content="on">
<meta name="HandheldFriendly" content="true">
<meta name="og:title" content="${title}" />
<meta name="og:url" content="${appUrl}/" />
<meta name="og:site_name" content="${siteName}">
<meta name="og:description" content="${description}" />
<meta name="og:image" content="${appUrl}/og-logo.png" />
<meta name="og:type" content="website" />
<meta name="og:updated_time" content="${currentTime}" />
<meta http-equiv="last-modified" content="${currentTime}">
<meta name="last-modified" content="${currentTime}">
<meta name="twitter:card" content="summary_large_image">
<meta name="twitter:site" content="@${twitter}">
<meta name="twitter:creator" content="@${twitter}">
<meta name="twitter:title" content="${title}">
<meta name="twitter:description" content="${description}">
<meta name="twitter:image:src" content="${appUrl}/tw-logo.png">
<meta name="twitter:url" content="${appUrl}/">
     `;
      document.head.innerHTML = document.head.innerHTML + metaTags;
      let keyword = document.querySelector('meta[name="keywords"]');
      keyword.setAttribute(
        "content",
        `${keyword.getAttribute("content")}, ${newKeywords.join(',')}`
      );

      document
        .querySelectorAll('meta[name$="description"]')
        .forEach((description) => {
          description.setAttribute("content", description2);
        });

      document.querySelectorAll('meta[name$="url"]').forEach((url) => {
        url.setAttribute("content", `${appUrl}${this.$route.path}`);
      });
      document.querySelectorAll('meta[name$="title"]').forEach((url) => {
        url.setAttribute("content", `${newTitle} | ${title}`);
      });
      document.querySelectorAll('meta[name$="src"]').forEach((url) => {
         url.setAttribute("content", logoUrl)
      });
      setTitle(
        {},
        `${newTitle} | ${title}`
      );
    }
