/* eslint-disable no-empty-pattern */

import axios from 'axios';
import { skills } from '@/helpers'

const API = `${process.env.VUE_APP_BASE_URI}`;

export default {
  state: {
    jobs: [],
    job: {
      skills: [],
      company: {}
    },
    stats: {},
    countries: [],
    states: [],
    cities: [],
    links: {
      contract_type: [],
      location: [],
      type: [],
      search: []
    },
    new_job: {
      __saved: false
    },
    searches: [],
    show_validation_errors: false,
    trigger_payment : false
  },
  getters: {
    jobs: state => (state.jobs && state.jobs.data) || [],
    jobs_meta: state => (state.jobs && state.jobs.meta) || {},
    job: state => state.job,
    stats: state => state.stats,
    countries: state => state.countries,
    states: state => state.states,
    cities: state => state.cities,
    links: state => state.links,
    new_job: state => state.new_job,
    searches: state => state.searches ? [...new Set(state.searches.map(s => s.value))] : [],
    show_validation_errors: state => state.show_validation_errors,
    skills: () => skills.map(s => s.title),
    meta_data: state => {
      let keywordShuffle = ['Vuejs', 'Vue.js']
      let mainKeyword = keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]
      let twitter = "vuejobs_";
      let siteName = process.env.VUE_APP_NAME;
      let appUrl = process.env.VUE_APP_BASE_URL;
      let keywords = skills.map(s => s.title);
      let logoUrl = `${appUrl}/og-logo.png`
      let jobTitles = [
        "Frontend Developer",
        "Frontend Engineer",
        "Javascript Developer",
        "Fullstack Developer",
        "Fullstack Engineer",
      ];
      let description1 = `Looking for a ${mainKeyword} job? ${siteName} is the #1 ${mainKeyword} Job Board and has ${
        state.stats.jobs_count - 1
      }+ ${mainKeyword} jobs as a ${jobTitles.join(",")} and more!`;
      let title = `${siteName} – Browse through dozens of ${mainKeyword} openings`;
      let description2 = `${siteName} is the best place to hire or get hired as ${keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]} developer. Find ${keywordShuffle[Math.floor(Math.random() * keywordShuffle.length)]} talent and reach to thousands of developers.`
      let description = Math.random() ? description1 : description2

      return {
        twitter,
        appUrl,
        keywords,
        title,
        description,
        jobTitles,
        siteName,
        description2,
        logoUrl,
        mainKeyword,
      }
    },
    trigger_payment: state => state.trigger_payment,
  },
  mutations: {
    TRIGGER_PAYMENT(state, value) {
      state.trigger_payment = value
    },
    SET_JOBS(state, array) {
      state.jobs = array;
    },
    SET_NEW_JOB(state, array) {
      state.new_job = array;
    },
    SET_JOB(state, obj) {
      state.job = obj;
    },
    SET_STATS(state, obj) {
      state.stats = obj;
    },
    SET_STATES(state, array) {
      state.states = array;
    },
    SET_CITIES(state, array) {
      state.cities = array;
    },
    SET_COUNTRIES(state, array) {
      state.countries = array;
    },
    SET_LINKS(state, array) {
      state.links = array;
    },
    SET_SEARCHES(state, array) {
      state.searches = array;
    },
    SET_SHOW_VALIDATION_ERRORS(state, value) {
      state.show_validation_errors = value
    },
  },
  actions: {
    fetchJobs({
      commit
    }, queryParams) {
      return new Promise((resolve, reject) => {
        let url = `${API}/jobs?${queryParams}`;
        axios.get(url)
          .then(async ({
            data
          }) => {

            commit('SET_JOBS', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchJob({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        let url = `${API}/jobs/${id}`;
        axios.get(url)
          .then(async ({
            data : {data}
          }) => {

            commit('SET_JOB', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    submitSubscribe({}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${API}/subscribers`;
        axios.post(url, payload)
          .then(async ({
            data
          }) => {

            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchCountries({
      commit
    }) {
      return new Promise((resolve, reject) => {
        let url = `${API}/countries`;
        axios.get(url)
          .then(async ({
            data : { data }
          }) => {

            commit('SET_COUNTRIES', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchStates({
      commit
    }) {
      return new Promise((resolve, reject) => {
        let url = `${API}/states`;
        axios.get(url)
          .then(async ({
            data : { data }
          }) => {

            commit('SET_STATES', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchCities({
      commit
    }) {
      return new Promise((resolve, reject) => {
        let url = `${API}/cities`;
        axios.get(url)
          .then(async ({
            data : { data }
          }) => {
  
            commit('SET_CITIES', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchLinks({
      commit
    }) {
      return new Promise((resolve, reject) => {
        let url = `${API}/links`;
        axios.get(url)
          .then(async ({
            data : { data }
          }) => {
  
            commit('SET_LINKS', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    // eslint-disable-next-line no-empty-pattern
    submitJob({}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${API}/jobs`;
        axios.post(url, payload)
          .then(async ({
            data
          }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchSearches({
      commit
    }) {
      return new Promise((resolve, reject) => {
        let url = `${API}/top-searches`;
        axios.get(url)
          .then(async ({
            data: {data}
          }) => {

            commit('SET_SEARCHES', data);
            resolve([...new Set(data.map(s => s.value).filter(s => s && s.length > 3))]);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    fetchStats({
      commit
    }) {
      return new Promise((resolve, reject) => {
        let url = `${API}/stats`;
        axios.get(url)
          .then(async ({
            data: {data}
          }) => {

            commit('SET_STATS', data);
            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    submitContact({}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${API}/contact-us`;
        axios.post(url, payload)
          .then(async ({
            data
          }) => {

            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
    submitUnsubscribe({}, payload) {
      return new Promise((resolve, reject) => {
        let url = `${API}/unsubscribe`;
        axios.post(url, payload)
          .then(async ({
            data
          }) => {

            resolve(data);
          })
          .catch((error) => {
            reject(error.response)
          });
      });
    },
  }
}
