import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import 'vue-toast-notification/dist/theme-default.css';
import ImageUploader from 'vue-image-upload-resize'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import store from './store'
import VeeValidate from 'vee-validate';
import VueToast from 'vue-toast-notification';
import Flutterwave from 'vue-flutterwave'
import LottieAnimation from 'lottie-web-vue'

Vue.use(Flutterwave, { publicKey: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY })
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});
Vue.use(VueToast);

Vue.use(LottieAnimation);
Vue.use(ImageUploader);
Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.prototype.$baseApi = process.env.VUE_APP_BASE_URI;
Vue.component('multi-select', Multiselect)
Vue.use(VueAxios, axios)
Vue.use(Vuex)
new Vue({
  router,
  store: new Vuex.Store(store),
  render: h => h(App)
}).$mount('#app')
