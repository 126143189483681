<template>
    <section id="home" class="h-full"> 
        <div class="bg-blue-light  lg:pl-24 lg:pr-24 p-10 lg:p-6 h-128">
            <nav-bar></nav-bar>

            <div class="flex items-center h-full flex-col mt-20">
                <div class="">
                    <h1 class="text-3xl lg:text-6xl text-center text-blue-primary lg:w-4/6 mx-auto ">SAVE MONEY BY SAVING TIME</h1>
                    <h6 class="text-md lg:w-3/6 mx-auto text-center opacity-70 mt-4">Leaf allows you to create and send invoices, manage projects, track time, as well as process payroll for your team all in one place.</h6>
                    <div class="lg:w-3/6 mx-auto mt-7 text-center">
                         <a href="https://alpha.leafbmp.com/register" target="_blank"><button class="bg-blue-primary text-bold text-sm text-white p-3 rounded-md pl-5 pr-5 hover:opacity-60">Get Started For Free</button></a>
                    </div>
                    
                </div>            
            </div>
        </div>
        <div class="flex flex-row items-center justify-center -mt-24">
            <img src="@/assets/images/dashboard_demo.png" />
        </div> 
    </section>
</template> 

<script>
import NavBar from "@/components/NavBar.vue"
export default {
    components: {NavBar}
}
</script>