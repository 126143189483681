<template>
  <div class="home">
    <header-section></header-section>
    <offering-section></offering-section>
    <features-section></features-section>
    <footer-section></footer-section>
  </div>
</template>

<script>
import HeaderSection from "@/components/HeaderSection.vue"
import OfferingSection from "@/components/OfferingSection.vue"
import FeaturesSection from "@/components/FeaturesSection.vue"
import FooterSection from "@/components/FooterSection.vue"
export default {
  name: 'HomeView',
  components: {
    HeaderSection, OfferingSection, FeaturesSection, FooterSection
  }
}
</script>
